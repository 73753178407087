import { ThunkDispatch } from '@reduxjs/toolkit'
import { FC, useEffect, useState } from 'react'
import { Button, Stack } from 'react-bootstrap'
import { MdAddCircleOutline, MdOutlineFileDownload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import DeleteModal from '../../../../../components/common/DeleteModal'
import Loader from '../../../../../components/common/Loader'
import { DEF_SORT_ORDER, DELETE_NOTE_CONTENT, DELETE_NOTE_TITLE, HIDE_TABLE_PAGINATOR, LIST_CRR_PAGE, PAGINATOR_ROWS_DEF, POP_UP_CLOSE_1000, REACT_TABLE_ORDER, ROLES_DEFAULT_SORT_COLUMN, SELECT_ONE_NOTE } from '../../../../../constants/global'
import AddNotesModal from './modals/AddNotesModal'
import { getNotesListAction, deleteNotesAction, companyNotesActions, viewNotesAction, exportNotesAction } from '../../../../../redux/companyNotesModule/notesSlice'
import ViewNotesModal from './modals/ViewNotesModal'
import CommonFormikComponent from '../../../../../components/common/CommonFormikComponent'
import ThemeInputSearch from '../../../../../components/common/InputSearch'
import { SearchSchema } from '../../../../../validations/search'
import DataGridCheckboxCommon from '../../../../../components/common/dataGrid/DataGridCheckboxCommon'
import { toast } from 'react-toastify'
import moment from 'moment'
import ExportPDFModal from './modals/ExportPDFModal'
import { FaInfoCircle } from 'react-icons/fa'
import RenderTableAction from '../../../../../components/common/dataGrid/ActionTableCell'
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";

interface INotesTabs {
    
}
const NotesTab: FC<INotesTabs> = ({
   
}) => {
    const caseID = useSelector((state: any) => state?.companyCaseSlice?.caseID);
    const caseDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);
    const isLoading = useSelector((state: any) => state.companyNotesSlice.loading);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [deleteShow, setDeleteShow] = useState(false);
    const [addNotesModalShow, setAddNotesModalShow] = useState(false);
    const [viewNotesModalShow, setViewNotesModalShow] = useState(false);
    const [notesInfo, setNotesInfo] = useState<any>(null);
    const [notesDelete, setNotesDelete] = useState({});
    const location = useLocation();
    const listObjData = useSelector((state: any) => state.companyNotesSlice.listObj);
    const [currentPage, setCurrentPage] = useState(listObjData ? listObjData?.currentPageProp : LIST_CRR_PAGE);
    const [sortOrder, setSortOrder] = useState(listObjData ? listObjData?.sortOrderProp : DEF_SORT_ORDER);
    const [sortField, setSortField] = useState(listObjData ? listObjData?.sortFieldProp : ROLES_DEFAULT_SORT_COLUMN);
    const [rows, setRows] = useState(listObjData ? listObjData?.rowsProp : PAGINATOR_ROWS_DEF);
    let locationObj = location.state !== undefined || location.state !== null ? location.state : undefined;
    const notesTypeList = useSelector((state: any) => state.companyNotesSlice.notes);
    const notesList = notesTypeList === null || notesTypeList === undefined ? [] : notesTypeList.records;
    const [searchKey, setSearch] = useState("");
    const [selectedNotesFromList, setSelectedNotesFromList] = useState([]);
    const [notesExportData, setNotesExportData] = useState([]);
    const [exportPDFModalShow, setExportPDFModalShow] = useState(false);

    // Initial Values
    const initialValues: Record<string, any> = {
        search: searchKey,
    };

    // Handle Submit
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        actions.setSubmitting(false);
        setSearch(values?.search?.trim());
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID,
            searchKey: values?.search?.trim(),
        }
        getNoteList(rolePayload);
    };
    
    
    useEffect(() => {
        if (!caseDetailsData || !caseID) return;
        let rolePayload: any = {};
        if (locationObj) {
            rolePayload = {
                sortOrder: listObjData.sortOrderProp,
                currentPage: listObjData.currentPageProp,
                rows: listObjData.rowsProp,
                sortField: listObjData.sortFieldProp,
                case_id: caseID,
                searchKey
            };
            setSortOrder(listObjData.sortOrderProp);
            setCurrentPage(listObjData.currentPageProp);
            setRows(listObjData.rowsProp);
            setSortField(listObjData.sortFieldProp);
        } else {
            rolePayload = {
                sortOrder: DEF_SORT_ORDER,
                currentPage: LIST_CRR_PAGE,
                rows: PAGINATOR_ROWS_DEF,
                sortField: ROLES_DEFAULT_SORT_COLUMN,
                case_id: caseID,
                searchKey
            };
            setSortOrder(DEF_SORT_ORDER);
            setCurrentPage(LIST_CRR_PAGE);
            setRows(PAGINATOR_ROWS_DEF);
            setSortField(ROLES_DEFAULT_SORT_COLUMN);
        }
        getNoteList(rolePayload);
        dispatch(companyNotesActions.changeListDataObj(rolePayload));
    }, [caseDetailsData])


    const getNoteList = (payload: any) => {
        dispatch(getNotesListAction(payload))
    };

    /**
     * Handle Sort Action
     *
     * @param {*} data
     */
    const onSort = (order: string, field: string) => {
        let sortOrder = order === REACT_TABLE_ORDER ? DEF_SORT_ORDER : order;
        let sortField = field;
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID,
            searchKey
        }
        setSortOrder(sortOrder);
        setSortField(field);
        getNoteList(rolePayload);
        dispatch(companyNotesActions.changeListDataObj(rolePayload));
    };

    /**
     * Handle Page Change Action
     *
     * @param {*} data
     */
    const handlePageChange = (newPage: number, rows: any) => {
        let currentPage = newPage;
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID,
            searchKey
        }
        setCurrentPage(newPage);
        setRows(rows);
        getNoteList(rolePayload);
        dispatch(companyNotesActions.changeListDataObj(rolePayload));
    };

    const viewNoteInfo = (noteId: any) => {
        setNotesInfo(null);
        const payload = {
            noteId
        }
        dispatch(viewNotesAction(payload)).then((data: any) => {
            if (data.payload.statusCode == 200) {
                setNotesInfo(data.payload.data);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    }


    /**
    * Deleting Roles List
    *
    * @param {*} rowData
    * @returns {*}
    */

    const deleteNotes = (rowData: any) => {
        if (!rowData) return;
        const notes_id = rowData.notes_id;
        const deletePayload = {
            notes_id,
        };

        dispatch(deleteNotesAction(deletePayload)).then((data: any) => {
            const rolePayload = {
                sortOrder,
                sortField,
                currentPage,
                rows,
                case_id: caseID,
                searchKey,
            }
            if (data.payload.statusCode == 200) {
                setDeleteShow(false);
                getNoteList(rolePayload);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    };

    const handleViewPopupClick = (rowData: any) => {
        viewNoteInfo(rowData.notes_id);
        setViewNotesModalShow(true)
    }

    // Data Table Start  
    //Action Template
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderTableAction
            wrapperClassName="justify-content-center"
            controlId="documents"
            rowData={rowData}
            customButtons={[
                {
                    name: 'edit',
                    enabled: true,
                    type: 'button',
                    title: 'Edit',
                    class: 'edit',
                    icon: <RiEditLine size={20} />,
                    handler: () => handleEditPopupClick(rowData)
                },
                {
                    name: 'delete',
                    enabled: true,
                    type: 'button',
                    title: 'Delete',
                    class: 'delete',
                    icon: <RiDeleteBin6Line size={20} />,
                    handler: () => handleDeleteClick(rowData)
                }
            ]}
        />
        );
    };

    // Notes Click Handler
    const handleAddNotesClick = (rowData: any) => {
        setNotesInfo(null)
        setAddNotesModalShow(true);
    }

    const handleCloseAddNotesPopup = (rowData: any) => {
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID,
            searchKey,
        }
        setAddNotesModalShow(false);
        setNotesInfo(null);
        getNoteList(rolePayload);
    }

    const handleEditPopupClick = (rowData: any) => {
        viewNoteInfo(rowData.notes_id);
        setAddNotesModalShow(true)
    }

    const handleDeleteClick = (rowData: any) => {
        setDeleteShow(true)
        setNotesDelete(rowData);
    };

    const sNoCellTemplate = (rowData: any, index: any) => {
        return <span className="min-w-20 d-inline-block text-center">{index.rowIndex + 1}</span>
    }

    // Table Columns
    const columns = [
        {
            width: "50px",
            selectionMode: "multiple",
            headerClassName: "hideCheckInMobile",
            bodyClassName: "hideCheckInMobile",
        },
        {
            field: "s_no",
            header: "No.",
            body: sNoCellTemplate,
            width: '70px',
        },
        {
            field: "normal_notes_content",
            header: "Notes",
            body: (rowData:any) => <Link to="#" className='text-truncate mw-100 d-inline-block align-middle' onClick={() => handleViewPopupClick(rowData)}>{rowData?.normal_notes_content}</Link>
        },
        {
            field: "updated_at_without_timezone",
            header: "Last Updated",
            sortable: true,
            width: "150px",
            headerClassName: "text-center",
            bodyClassName: "text-center",
            body: (rowData:any) => <span className='pe-4'>{rowData?.updated_at_without_timezone}</span>
        },
        {
            field: "action",
            header: "Action",
            width: "150px",
            headerClassName: "theme-action-cell text-center",
            bodyClassName: "text-center",
            body: actionCellTemplate,
        },
    ];

    const onSelectionChange = (event: any) => {
        setSelectedNotesFromList(event.value)
    }

    //Export PDF Click Handler
    const exportPDFModalHandler = () => {
        let selectedIds = selectedNotesFromList.map((i: any) => i.notes_id);
        if (selectedIds.length === 0) {
            toast.error(SELECT_ONE_NOTE, { autoClose: POP_UP_CLOSE_1000 })
            return;
        }
        setNotesExportData([]);
        setExportPDFModalShow(true);
        const payload = {
            search: searchKey,
            notes_id: selectedIds,
            company_id: caseDetailsData?.company_id,
        }
        dispatch(exportNotesAction(payload)).then((data: any) => {
            if (data.payload.statusCode == 200) {
                const _notes: any = [];
                data.payload.data.forEach((element: any) => {
                    _notes.push({
                        id: element.notes_id,
                        items: [
                        {
                            title: "Created",
                            detail: `${moment(element.created_at).format('MM/DD/YYYY hh:mm:ss')}`,
                            className: "col-12 col-sm-6 text-capitalize",
                        },
                        {
                            title: "Last Updated",
                            detail: `${moment(element?.updated_at).format('MM/DD/YYYY hh:mm:ss')}`,
                            // detail: `${element.company.legal_name.toLowerCase()} <br /> ${moment(element.updated_at).format('MM/DD/YYYY hh:mm:ss')}`,
                            className: "col-12 col-sm-6 text-capitalize",
                        },
                        {
                            title: "Comments",
                            detail: element.note_content,
                            className: "col-12",
                        }]
                    })
                });
                setNotesExportData(_notes);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    }


    return (
        <>
            <Loader isLoading={isLoading} classCustom={'theme-loader-z-index'} />
            <>
                <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex-wrap pt-3 pb-2 align-items-start"
                >
                    <h5 className="my-auto fw-semibold me-auto mb-0 position-relative fw-normal me-auto">
                        {/* Notes Listing */}
                        <p className="mb-2 pt-1 opacity-75 fs-14">
                            <FaInfoCircle className="me-1 text-secondary-emphasis small" />
                            To be used for personal use (ONLY) and NOT intended to be shared with your Legal Team.
                        </p>
                    </h5>
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap ms-md-auto"
                    >
                            <CommonFormikComponent
                                validationSchema={SearchSchema}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                            >
                                {(formikProps) => (
                                    <>
                                        <Stack
                                            direction="horizontal"
                                            gap={3}
                                            className="flex-wrap flex-sm-nowrap"
                                        >
                                            <div className="w-220">
                                                <ThemeInputSearch
                                                    inputWrapClass="p-inputgroup"
                                                    controlId="search"
                                                    placeholder="Search Notes"
                                                    handleBlur={formikProps.handleBlur}
                                                    handleChange={formikProps.handleChange}
                                                    value={formikProps.values.search}
                                                    touched={formikProps.touched.search}
                                                    errorsField={formikProps.errors.search}
                                                />
                                            </div>
                                        </Stack>
                                    </>
                                )}
                            </CommonFormikComponent>

                            
                            <Button
                            variant="outline-primary"
                            className="d-md-inline-block d-none"
                            onClick={exportPDFModalHandler}
                            disabled={notesList?.length < 1 ? true : false}
                            >
                                <MdOutlineFileDownload
                                    size={18}
                                    className="me-2 align-text-top"
                                />
                                Export PDF
                            </Button>
                        
                        <Button
                            variant="primary"
                            onClick={handleAddNotesClick}
                        >
                            <MdAddCircleOutline size={17} className="me-2" />
                            Add Notes
                        </Button>
                    </Stack>
                </Stack>
                <div className="theme-tabs-table mb-4">
                    {/* <DataGridCommonNew
                        columns={columns}
                        data={notesList}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onSort={onSort}
                        tableRecords={notesTypeList}
                        rows={rows}
                        sortOrderProp={sortOrder}
                        sortFieldProp={sortField}
                        HideTablePaginator={true}
                    /> */}

                        <DataGridCheckboxCommon
                            columns={columns}
                            data={notesList}
                            HideTablePaginator={HIDE_TABLE_PAGINATOR}
                            selectedProducts={selectedNotesFromList}
                            onSelectionChange={onSelectionChange}
                            selectionMode="checkbox"
                        />

                </div>
            </>

            {/* Add Notes Modal */}
            <AddNotesModal
                show={addNotesModalShow}
                handleClose={() => handleCloseAddNotesPopup(false)}
                notesData={notesInfo}
            />

            {/* View Notes Modal */}
            <ViewNotesModal
                show={viewNotesModalShow}
                handleClose={() => setViewNotesModalShow(false)}
                notesData={notesInfo}
            />

             {/* Export PDF Modal */}
             <ExportPDFModal
                show={exportPDFModalShow}
                handleClose={() => setExportPDFModalShow(false)}
                notesExportData={notesExportData}
            />

            {/* Delete Modal */}
            <DeleteModal
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                handleDeleteClose={() => setDeleteShow(false)}
                deleteModalHeaderTitle={DELETE_NOTE_TITLE}
                deleteModalBodyContent={DELETE_NOTE_CONTENT}
                deleteRowData={notesDelete}
                customDeleteFunction={deleteNotes}
            />
        </>
    )
}

export default NotesTab